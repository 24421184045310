import { Box } from "@mui/system";
import { Routes, Route } from "react-router-dom";
import { GoogleAddressSearchProvider, useAppContext } from "../context";
import { Footer, Header, Sidebar } from "../layout";
import { FaultReportWithMap } from "./FaultReportWithMap";
import { DetailedFaultReport } from "./DetailedFaultReport";
import { FaultReportList } from "./FaultReportList";
import { FaultReportContextProvider } from "../context/FaultReportContext";
import { MaintenanceDialog } from "./MaintenanceDialog/MaintenanceDialog";
import { LoadingDialog } from "../parts/Spinner/LoadingDialog";
import { LoadingProvider } from "../parts/Spinner/LoadingProvider";
import { AppRoute, HomePageRoute, FaultStateVariant } from "../hooks/navigation/routes";
import { EnersisGeoMapProvider } from "../context/EnersisGeoMapContext";
import { getConfiguration } from "../config/Configuration";

const GOOGLE_MAPS_LIBRARIES = ["places", "geometry"];
// TODO: use env variable for more security
export const GOOGLE_MAPS_API_KEY = "AIzaSyAJKD2CxbWFEk5S2IVVD67fP7GdQJHy4T8";
const ENERSIS_API_KEY = getConfiguration().enersisApiKey;

const STUTTGART_COORDINATES = { lat: 48.783333, lng: 9.183333 };

export const App = () => {
  const { isHeadless, isLoading, maintenanceFetched } = useAppContext();

  return (
    <Box className="flex flex-col h-full" marginTop={isHeadless ? 0 : "100px"}>
      <MaintenanceDialog />
      <Header />
      <Box className="flex flex-1">
        <Sidebar />
        <LoadingProvider isLoading={!maintenanceFetched}>
          <GoogleAddressSearchProvider
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            googleMapslibraries={GOOGLE_MAPS_LIBRARIES}
            defaultFetchType="customer-scope"
            defaultCenter={STUTTGART_COORDINATES}
          >
            <EnersisGeoMapProvider apiKey={ENERSIS_API_KEY}>
              <Box className="p-5 w-full">
                <FaultReportContextProvider>
                  <Routes>
                    <Route path="/meldungen/:id" element={<DetailedFaultReport />} />

                    <Route
                      path={AppRoute.INAPPROVALVIEW}
                      element={<FaultReportList variant={FaultStateVariant.INAPPROVALVIEW} />}
                    />
                    <Route
                      path={AppRoute.INPROGRESSVIEW}
                      element={<FaultReportList variant={FaultStateVariant.INPROGRESSVIEW} />}
                    />
                    <Route
                      path={AppRoute.COMPLETEDVIEW}
                      element={<FaultReportList variant={FaultStateVariant.COMPLETEDVIEW} />}
                    />
                    <Route
                      path={AppRoute.CREATE_REPORT}
                      element={<FaultReportWithMap variant="create-fault-report" />}
                    />
                    {/* We're keeping the /start route for backwards compatibility with Kommunalplatform for now. */}
                    {Object.values(HomePageRoute).map((route) => (
                      <Route key={route} path={route} element={<FaultReportWithMap />} />
                    ))}
                  </Routes>
                </FaultReportContextProvider>
              </Box>
            </EnersisGeoMapProvider>
          </GoogleAddressSearchProvider>
        </LoadingProvider>
      </Box>
      <Footer />
      <LoadingDialog open={isLoading} />
    </Box>
  );
};

export default App;
