import { Box, Button, Card, CardActions, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ReactNode, useState } from "react";
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import { ImageIO } from "../../../dataQuery/imageApi";

const maxNumber = 69;

interface ImageUploaderProps {
  onChangeImages: (i: ImageIO[]) => void;
}

interface ImageTypeWithDataURL extends ImageType {
  dataURL: string
}

export function ImageUploader(props: ImageUploaderProps) {
  const [images, setImages] = useState<ImageListType>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    const validImages = imageList.filter(i => !!i.dataURL) as ImageTypeWithDataURL[];
    const imageIOList: ImageIO[] = validImages.map(i => {
      let name = i.file?.name;
      if (!name) {
        const b64StartingIndex = i.dataURL.indexOf(",") + 1;
        name = i.dataURL.slice(b64StartingIndex, b64StartingIndex + 20);
      }
      return ({ name, dataUrl: i.dataURL })
    });
    props.onChangeImages(imageIOList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps
      }) => (
        // write your building UI
        <Box display={"flex"} justifyContent="center" sx={{ width: "100%" }}>
          <Button
            variant="contained"
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            {...dragProps}
            sx={{flex: 1, marginRight: "20px"}}
          >
            Bilder hochladen
          </Button>
          <Button 
            sx={{flex: 1}} 
            variant="contained" 
            onClick={() => setOpenDialog(true)} 
            disabled={!images.length}
          >
            {images.length} Bilder
          </Button>
          <ImageListDialog 
            open={openDialog} 
            onClose={() => setOpenDialog(false)} 
            onDeleteAll={onImageRemoveAll}
          >
            <Grid container spacing={imageList.length > 1 ? 3 : 0} marginX={"auto"}>
              {imageList.map((image, index) => (
                <Grid item key={image.file?.name || "image-uploader" + index}>
                  <Card key={index} sx={{ marginBottom: "10px" }}>
                    {/* <CardHeader title={image.file?.name} /> */}
                    <CardMedia
                      title={image.file?.name}
                      component="img"
                      height="140"
                      image={image.dataURL}
                      alt="green iguana"
                    />
                    <CardActions>
                      <Button size="small" color="error" onClick={() => onImageRemove(index)}>Löschen</Button>
                    </CardActions>
                    {/* <img src={image.dataURL} alt="" width="100" /> */}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </ImageListDialog>
        </Box>
      )}
    </ImageUploading>
  );
}

interface ImageListDialogProps {
  open: boolean;
  onClose: () => void;
  onDeleteAll: () => void;
  children: ReactNode;
}
function ImageListDialog(props: ImageListDialogProps) {
  function handleOnDeleteAll() {
    props.onClose();
    props.onDeleteAll();
  }
  return (
    <Dialog
      maxWidth={"lg"}
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Bilder</DialogTitle>
      <DialogContent dividers>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={props.onClose}>Schließen</Button>
        <Button color="error" onClick={handleOnDeleteAll}>Alle Löschen</Button>
      </DialogActions>
    </Dialog>
  );
}
