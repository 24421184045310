export const FaultStateVariant = {
  //Renamte it to  view
  INPROGRESSVIEW: "INPROGRESSVIEW",
  INAPPROVALVIEW: "INAPPROVALVIEW",
  COMPLETEDVIEW: "COMPLETEDVIEW",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the type the same as the variable
export type FaultStateVariant = typeof FaultStateVariant[keyof typeof FaultStateVariant];

export const FaultReportRoutes = {
  INPROGRESSVIEW: "/meldungen-active",
  COMPLETEDVIEW: "/meldungen-archive",
  INAPPROVALVIEW: "/meldungen-freigabe",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the type the same as the variable
export type FaultReportRoutes = typeof FaultReportRoutes[keyof typeof FaultReportRoutes];

export const HomePageRoute = {
  COCKPIT: "/cockpit",
  START: "/start",
  ROOT: "/",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the type the same as the variable
export type HomePageRoute = typeof HomePageRoute[keyof typeof HomePageRoute];

export const AppRoute = {
  ...FaultReportRoutes,
  ...HomePageRoute,
  CREATE_REPORT: "/meldung-erfassen",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the type the same as the variable
export type AppRoute = typeof AppRoute[keyof typeof AppRoute];

export type AppRouteKey = keyof typeof AppRoute;
